import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "input", "icon" ]

  toggle(e) {
    e.preventDefault();

    if (this.inputTarget.getAttribute("type") == "text") {
      this.inputTarget.setAttribute("type", "password");
      this.iconTarget.classList.add("fa-eye");
      this.iconTarget.classList.remove("fa-eye-slash");
    } else {
      this.inputTarget.setAttribute("type", "text");
      this.iconTarget.classList.remove("fa-eye");
      this.iconTarget.classList.add("fa-eye-slash");
    }
  }
}

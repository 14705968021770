import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "modalContainer", "modalBody", "modalDialog" ]

  initialize() {
    this.modalInstance = $(this.modalContainerTarget).modal({ show: false });
  }

  // Triggered from an "ajax:success" event from a remote link/button
  openModal(e) {
    this.resetModalSize();
    this.modalBodyTarget.innerHTML = e.detail[2].responseText;
    this.modalInstance.modal("show");
  }

  updateModal(e) {
    this.modalBodyTarget.innerHTML = e.detail[2].responseText;
    this.modalInstance.modal("show");
  }

  closeModal(e) {
    e.preventDefault();
    this.modalInstance.modal("hide");
  }

  openLargeModal(e) {
    this.resetModalSize();
    this.modalDialogTarget.classList.add("modal-lg");
    this.modalBodyTarget.innerHTML = e.detail[2].responseText;
    this.modalInstance.modal("show");
  }

  resetModalSize() {
    this.modalDialogTarget.classList.remove("modal-sm", "modal-lg", "modal-xl");
  }
}

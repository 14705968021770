import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "input", "preview" ]

  updatePreview() {
    const file = this.inputTarget.files[0];

    const reader = new FileReader();
    reader.onloadend = (loadEvent) => {
      const newPreview = document.createElement('img');
      newPreview.classList.add("vaccination-card-thumb", "rounded-xl", "mb-3")
      newPreview.dataset.photoUploadTarget = "preview";

      newPreview.src = loadEvent.target.result;
      this.previewTarget.replaceWith(newPreview);
    }

    if (file.type == "image/heic" || file.type == "image/heif") {
      return this.setUnavailablePreview();
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  setUnavailablePreview() {
    const newPreview = document.createElement("div");
    newPreview.classList.add("d-flex", "flex-column", "align-items-center");
    newPreview.dataset.photoUploadTarget = "preview";
    const icon = document.createElement("i");
    icon.classList.add("far", "fa-eye-slash", "rounded-xl", "vaccination-card-placeholder", "p-3", "mb-3");
    const text = document.createElement("span");
    text.classList.add("text-danger", "text-center", "mb-1");
    text.innerHTML = "Preview is not available for HEIC/HEIF images, image will be processed after it's uploaded";
    newPreview.append(icon);
    newPreview.append(text);
    this.previewTarget.replaceWith(newPreview);
  }

  upload(event) {
    event.preventDefault();
    this.inputTarget.click();
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['userField', 'typeField'];

  connect() {
    if (this.hasTypeFieldTarget) {
      this.toggle_fields($(this.typeFieldTarget).val());
    }
  }

  type_changed(e) {
    this.toggle_fields(e.target.value);
  }

  toggle_fields(value) {
    if (value === 'invite') {
      $(this.userFieldTargets).addClass('d-none');
      $(this.userFieldTargets).find('input:not(.attributes)').attr('disabled', true);
      $(this.userFieldTargets).find('#user_gender').removeAttr('required');
    } else {
      $(this.userFieldTargets).removeClass('d-none');
      $(this.userFieldTargets).find('input').attr('disabled', false);
      $(this.userFieldTargets).find('#user_gender').attr('required', true);
    }
  }
}

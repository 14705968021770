// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jquery from 'jquery';
window.$ = window.jquery = jquery;

import("controllers")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("controllers")
require("./password_strength")
require("@nathanvda/cocoon")
import("bootstrap")
import "@fortawesome/fontawesome-free/js/all";

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'submitButton', 'input'];

  connect() {
    this.submitButtonTarget.disabled = true;

    Array.from(this.formTarget).forEach((element) => {
      var obj = this;

      if (['INPUT', 'SELECT', 'TEXTAREA'].includes(element.tagName)) {
        element.dataset.originalValue = obj.getElementValue(element);
      }
    });
  }

  check_changes(e) {
    var obj = this;
    var changed = false;

    Array.from(this.formTarget).forEach((element) => {
      if (['INPUT', 'SELECT', 'TEXTAREA'].includes(element.tagName)) {
        if (element.dataset.originalValue !== obj.getElementValue(element)) {
          changed = true;
        }
      }
    });

    if (changed) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  validate_input(e) {
    this.inputTargets.forEach(input => {
      input.classList.remove('is-invalid');

      if (input.nextElementSibling && input.nextElementSibling.classList.contains('error')) {
        input.nextElementSibling.remove();
      }

      if (input.value === "") {
        input.classList.add('is-invalid');
        input.insertAdjacentHTML('afterend', "<span class=\"error text-danger\">must be filled in.</span>")
      }
    });
  }

  getElementValue(element) {
    if (element.type === 'checkbox' || element.type === 'radio') {
      return element.checked.toString();
    } else {
      return element.value;
    }
  }
}
